import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { supabase } from './supabaseClient';
import styled from 'styled-components';

// Lazy load components
const Dashboard = lazy(() => import('./components/Dashboard'));
const SignIn = lazy(() => import('./components/SignIn'));
const SignUp = lazy(() => import('./components/SignUp'));
const HomePage = lazy(() => import('./components/HomePage'));
const HomePageHeadshots = lazy(() => import('./components/HomePage-Headshots')); // Add this line
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const NotFound = lazy(() => import('./components/NotFound'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const RefundPolicy = lazy(() => import('./components/RefundPolicy'));
const Pricing = lazy(() => import('./components/Pricing'));

// Styled components for the loader
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to bottom right, #000000, #111111);
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #D4AF37;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.p`
  color: #F8F0E3;
  font-family: 'Circular', 'Nunito', sans-serif;
  font-size: 1.2rem;
  margin-left: 15px;
`;

// Loader component
const Loader = () => (
  <LoaderWrapper>
    <Spinner />
    <LoadingText>Loading...</LoadingText>
  </LoaderWrapper>
);

function AuthWrapper() {
  const navigate = useNavigate();

  React.useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        console.log('User signed in:', session.user);
        navigate('/dashboard'); // Redirect to dashboard after successful sign-in
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate]);

  return null;
}

function App() {
  console.log('App rendered, current path:', window.location.pathname);
  return (
    <Router>
      <UserProvider>
        <AuthWrapper />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/headshots" element={<HomePageHeadshots />} /> // Add this line
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </UserProvider>
    </Router>
  );
}

export default App;